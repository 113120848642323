<template>
  <div>
    <!-- :src="https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220608_173154_62a06c8ab5efa.png" -->
    <NavBar :title="'基础信息'"></NavBar>
    <van-cell title="门店图片" is-link center>
      <template #right-icon>
        <van-uploader :before-read="afterRead">
          <img v-if="user.logo" class="logo" :src="user.logo" alt="店铺图片" />
          <p v-else>点击上传</p>
        </van-uploader>
      </template>
    </van-cell>

    <van-cell
      title="门店名称"
      is-link
      :value="user.title"
      @click="edit('门店名称', 'title')"
    />
    <van-cell
      title="门店描述"
      is-link
      :value="user.desc"
      @click="edit('门店描述', 'desc')"
    />
    <van-cell
      title="门店地区"
      is-link
      :value="user.address_text"
      @click="showAddr"
    />
    <van-cell
      title="门店详细地址"
      is-link
      :value="user.address"
      @click="edit('门店详细地址', 'address')"
    />
    <van-cell
      title="联系人"
      is-link
      :value="user.name"
      @click="edit('联系人', 'name')"
    />
    <van-cell
      title="联系人手机号"
      is-link
      :value="user.phone"
      @click="edit('联系人手机号', 'phone')"
    />
    <van-cell
      title="代理商推广码"
      :value="user.agent.phone"
      @click="edit('代理商推广码', 'agent.phone')"
    />
    <van-cell
      title="代理商姓名"
      :value="user.agent.name"
      @click="edit('代理商姓名', 'agent.name')"
    />
    <van-popup
      v-model:show="show"
      position="bottom"
      :style="{ height: '30%' }"
      :close-on-click-overlay="false"
    >
      <div class="from">
        <h1>{{ title }}</h1>
        <textarea rows="8" v-model="editFrom[type]"></textarea>
        <div class="btn">
          <button @click="show = false">取消</button
          ><button @click="confirm">确定</button>
        </div>
      </div>
    </van-popup>
    <Address
      :county="user.county"
      v-if="showAddress"
      @confirm="confirmAddress"
    />
  </div>
</template>
<script>
import { Row, Popup, Icon, Cell, CellGroup, Uploader } from 'vant'
import Address from '../../components/Address/index.vue'
export default {
  name: 'MyInfo',
  components: {
    [Row.name]: Row,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [CellGroup.name]: CellGroup,
    [Uploader.name]: Uploader,
    Address,
  },
  data() {
    return {
      show: false,
      user: this.$state.user,
      editFrom: this.$state.user,
      type: '',
      address: '',
      showAddress: false,
    }
  },
  methods: {
    showAddr() {
      this.showAddress = true
    },
    afterRead(file) {
      this.$http({
        url: '/merchant/sysConfigImageUpload',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: { picture: file },
      }).then((res) => {
        this.editFrom.logo = res.data.src_url
        this.confirm()
      })
    },
    confirmAddress(res) {
      if (
        res &&
        res !== null &&
        res.length === 3 &&
        res[0].code !== this.user.province &&
        res[1].code !== this.user.city &&
        res[2].code !== this.user.county
      ) {
        this.editFrom.province = parseInt(res[0].code)
        this.editFrom.city = parseInt(res[1].code)
        this.editFrom.county = parseInt(res[2].code)
        this.address = res[0].name + '-' + res[1].name + '-' + res[2].name
        this.confirm()
      }
      this.showAddress = false
      this.type = ''
    },
    edit(name, col) {
      if (col === 'agent.name' || col === 'agent.phone') {
        return
      }
      this.type = col
      this.show = true
      this.title = '修改' + name
    },
    getUserInfo() {
      this.$http('/merchant/info').then((res) => {
        this.user = res.data
        this.$state.user = res.data
      })
    },
    confirm() {
      this.$http({
        url: '/merchant/userEdit',
        method: 'post',
        data: this.$http.adornData(this.editFrom),
      }).then(() => {
        this.show = false
        this.$notify({ type: 'success', message: '修改成功' })
        this.getUserInfo()
      })
    },
  },
}
</script>
<style lang="less" scoped>
.logo {
  width: 50px;
  height: 50px;
}
.from {
  text-align: center;
  overflow: hidden;
  height: 100%;
  h1 {
    margin: 0 auto;
    padding: 10px 0 15px 0;
    font-size: 20px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 35px;
  }
  textarea {
    width: 80%;
    height: 70px;
    background: #f3f3f3;
    border-radius: 8px 8px 8px 8px;
    border: none;
  }
  .btn {
    padding: 15px 0;
  }
  button {
    border: none;
    width: 38%;
    height: 40px;
    // margin-top: 32px;
  }
  button:nth-child(1) {
    color: rgba(0, 0, 0, 0.4);
    // width: 151px;
    // height: 44px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding-left: 10px;
    margin-right: 10px;
    // border: 1px solid rgba(0, 0, 0, 0.4);
  }
  button:nth-child(2) {
    // width: 152px;
    // height: 44px;
    background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
    border-radius: 8px 8px 8px 8px;
    color: #ffffff;
    opacity: 1;
  }
}
</style>
